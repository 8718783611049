<template>
    <section style="background-color: #F9F9F9 0% 0% no-repeat padding-box;" class="py-2 mt-2 selectedButtonCreateUser">
      <Toast />
      <div class="grid grid-cols-1 lg:grid-cols-3 text-center">
        <div class="lg:text-left w-full">
          <Button
            @click="$router.back()"
            style="background-color: #F8F8F8; border: transparent"
            class="mr-4 mb-2 buttonBack font-medium"
            label="Volver"
            icon="pi pi-angle-left" />
        </div>
        <div class="text-2xl font-bold flex items-center justify-center text-gray-500 w-full my-4 lg:my-0">
          <div class="flex w-full justify-center text-center">
            <i class="pi pi-pencil mr-4 text-2xl"></i>
            <h1>Editar usuario</h1>
          </div>
        </div>
        <div class="w-full lg:text-right">
          <Button @click="validar" style="background-color: #1C3FAA" class="lg:mr-4 mb-2" label="Guardar" icon="pi pi-save" />
        </div>
      </div>
      <div v-if="saving">
        <div class="flex items-center text-xs" v-if="errors.firt_name || errors.last_name || errors.user_name || errors.grupoId || errors.email || errors.type_document_id || errors.localizacion">
          <p class="mr-2 font-bold">Por favor valida los siguientes campos para continuar: </p>
          <MessageError v-if="errors.firt_name" class="mx-2 bg-red-100 p-1 rounded-md" :text="errors.firt_name" />
          <MessageError v-if="errors.last_name" class="mx-2 bg-red-100 p-1 rounded-md" :text="errors.last_name" />
          <MessageError v-if="errors.user_name" class="mx-2 bg-red-100 p-1 rounded-md" :text="errors.user_name" />
          <MessageError v-if="errors.grupoId" class="mx-2 bg-red-100 p-1 rounded-md" :text="errors.grupoId" />
          <MessageError v-if="errors.email" class="mx-2 bg-red-100 p-1 rounded-md" :text="errors.email" />
          <MessageError v-if="errors.localizacion" class="mx-2 bg-red-100 p-1 rounded-md" :text="errors.localizacion" />
          <MessageError v-if="errors.type_document_id" class="mx-2 bg-red-100 p-1 rounded-md" :text="errors.grupoId" />
        </div>
      </div>
      <div class="mt-2 w-full">
        <div class="boxed-tabs nav nav-tabs rounded-md border-b w-full" role="group">
          <router-link v-for="(item, index) in items"  :key="index"
                  :id="item.id"
                  :to="item.to"
                  :class="item.class"
                  class="p-2 border-0"
          >
              {{ item.label }}
          </router-link>
        </div>
        <router-view />
      </div>
    </section>
  </template>
  <script>
    import usuariosStore from '../../../../../stores/usuarios.store'
    import UsuariosService from '../../../../../services/usuarios.service'
    import LocalizacionService from '../../../../../services/localizacion.service'
    import permisosStore from '../../../../../stores/permisos.store'
    import { onMounted, ref, computed, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { useField, useForm } from 'vee-validate'
    import { useToast } from 'primevue/usetoast'
    import Swal from 'sweetalert2'
    import * as yup from 'yup'
    export default {
    name: 'menuEditarUsuarios',
    setup () {
      // Services
      const _UsuariosService = ref(new UsuariosService())
      const _LocalizacionService = ref(new LocalizacionService())
      const route = useRoute()
      const router = useRouter()
      const toast = useToast()
      // References
      const saving = ref(false)
      const back = process.env.VUE_APP_MICROSERVICE_API_USUARIOS.split('/api')[0]
      // Computed
      const items = computed(() => {
        return [
            {
              id: 'informacion-basica',
              to: { name: 'ajustes.usuarios.editar.informacion', params: { id: route.params.id } },
              class: getActive([
                'ajustes.usuarios.editar.informacion'
              ]),
              label: 'Información Básica'
            },
            {
              id: 'permisos',
              to: { name: 'ajustes.usuarios.editar.permisos', params: { id: route.params.id } },
              class: getActive([
                'ajustes.usuarios.editar.permisos'
              ]),
              label: 'Permisos'
            }
        ]
      })
      const userData = computed(() => usuariosStore.getters._usuario_data)
      const permisosPorUsuario = computed(() => permisosStore.getters._permisosGuardar)
      const grupoSeleccionado = computed(() => permisosStore.getters._grupoSelecionado)
      const permisosGrupo = computed(() => permisosStore.getters._permisosActivos)
      const esEmpleado = computed(() => usuariosStore.getters._empleado)
      const dataEmpleado = computed(() => usuariosStore.getters._data_empleado)
      // Data
      const schema = yup.object({
        firt_name: yup.string().required('Nombre').label(''),
        last_name: yup.string().required('Apellido').label(''),
        user_name: yup.string().required('Usuario').label(''),
        phone: yup.string().label(''),
        email: yup.string().email().label(''),
        password: yup.string().label(''),
        confirmation_password: yup.string().label(''),
        state: yup.bool().label(''),
        type_document_id: yup.string().label(''),
        number_document: yup.string().label(''),
        localizacion: yup.number().label(''),
        adjuntos: yup.object().label(''),
        direccion: yup.string().label(''),
        persona_id: yup.number().label(''),
        grupoId: yup.number().label('').required('Grupo'),
        permisos: yup.array().label(''),
        admin: yup.boolean().label('')
      })
      const { errors, values: model, handleSubmit } = useForm({
        validationSchema: schema
      })
      useField('firt_name')
      useField('last_name')
      useField('user_name')
      useField('phone')
      useField('email')
      useField('password')
      useField('state', null, { initialValue: true })
      useField('type_document_id', null, { initialValue: 'CC' })
      useField('number_document')
      useField('adjuntos')
      useField('localizacion')
      useField('direccion')
      useField('persona_id')
      useField('grupoId')
      useField('permisos')
      useField('confirmation_password')
      useField('admin')
      // Methods
      const getActive = (routeNames = []) => {
          if (routeNames.includes(route.name)) {
          return 'on'
          }
          return 'off'
      }
      const getLocalizaciones = () => {
        const localizaciones = []
          _LocalizacionService.value.paginate().then(({ data }) => {
            data.map(a => {
              localizaciones.push({
                id: a.id,
                municipio: a.nomMpio,
                departamento: a.nomDpto,
                name: a.id + ' - ' + a.nomMpio + ' - ' + a.nomDpto
              })
            })
            usuariosStore.commit('setLocalizaciones', localizaciones)
          })
        }
      const findUsuario = () => {
        _UsuariosService.value.find(parseInt(route.params.id)).then(({ data }) => {
          model.grupoId = data.grupo.id
          const object = {
            firt_name: data.persona.firt_name,
            last_name: data.persona.last_name,
            user_name: data.user_name,
            email: data.persona.email ? data.persona.email : '',
            phone: data.persona.telefono ? data.persona.telefono : '',
            state: data.state,
            id: parseInt(route.params.id),
            direccion: data.persona.direccion ? data.persona.direccion : '',
            type_document_id: data.persona.type_document_id,
            number_document: data.persona.number_document ? data.persona.number_document : '',
            localizacion: data.persona.localizacion ? data.persona.localizacion : 0,
            persona_id: data.persona.id,
            password: '',
            confirmation_password: '',
            actualizadoPor: data.actualizo ? data.actualizo.persona.full_name : '',
            actualizadoALas: data.actualizo ? data.updatedAt : '',
            admin: data.admin
          }
          if (data.persona.imagen) {
            usuariosStore.commit('setImagenPerfil', back + data.persona.imagen)
          }
          permisosStore.dispatch('listadoGrupos')
          usuariosStore.commit('setUsuarioData', object)
          permisosStore.commit('setGrupoSelecionado', data.grupo.id)
          permisosStore.dispatch('listarPermisosPorGrupo').then(() => {
            permisosStore.dispatch('validarPermisosIndividuales', route.params.id)
          })
          if (data.persona.empleado) {
            usuariosStore.commit('setEsEmpleado', true)
            const empleadoInfo = {
              sede: data.persona.empleado.contratos.estacion_trabajo.branch_office_id,
              area: data.persona.empleado.contratos.estacion_trabajo.area_id,
              cargo: +data.persona.empleado.Cargo
            }
            usuariosStore.commit('setDataEmpleado', empleadoInfo)
          } else {
            usuariosStore.commit('setEsEmpleado', false)
            const empleadoInfo = {
              sede: '',
              area: '',
              cargo: ''
            }
            usuariosStore.commit('setDataEmpleado', empleadoInfo)
          }
        })
      }
      const validar = () => {
        saving.value = true
        guardar()
      }
      const guardar = handleSubmit((values) => {
        console.log('esta entrando aqui')
        const object = { ...values, id: route.params.id }
        const crear = () => {
          _UsuariosService.value.put(object).then(({ data }) => {
            if (data.error) {
              toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Error al editar usuario', life: 3000 })
            } else {
              Swal.fire({
                title: 'Exito',
                text: 'Usuario actualizado con exito',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then(() => {
                router.push({ name: 'ajustes.usuarios' })
              })
            }
          })
        }
        if (esEmpleado.value) {
          if (!dataEmpleado.value.sede || !dataEmpleado.value.area || !dataEmpleado.value.cargo) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Si vas a crear al usuario como empleado debes completar los datos de sede, area y cargo', life: 5000 })
          } else {
            object.dataEmpleado = dataEmpleado.value
            crear()
          }
        } else {
          if (model.password || model.confirmation_password) {
            if (model.password === model.confirmation_password) {
              crear()
            } else {
              Swal.fire({
                title: 'Advertencia',
                text: 'Si realiza cambio de contraseñas deben coincidir',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              })
            }
          } else {
            crear()
          }
        }
      })
      watch(userData.value, (n, v) => {
        model.firt_name = userData.value.firt_name
        model.last_name = userData.value.last_name
        model.user_name = userData.value.user_name
        model.phone = userData.value.phone
        model.email = userData.value.email
        model.password = userData.value.password
        model.state = userData.value.state
        model.type_document_id = userData.value.type_document_id
        model.number_document = userData.value.number_document
        model.localizacion = userData.value.localizacion
        model.direccion = userData.value.direccion
        model.adjuntos = userData.value.foto_perfil
        model.persona_id = userData.value.persona_id
        model.confirmation_password = userData.value.confirmation_password
        model.admin = userData.value.admin
      })
      watch(grupoSeleccionado, (n, v) => {
        model.grupoId = n
      })
      watch(permisosPorUsuario, (n, v) => {
        if (permisosGrupo.value === n) {
          model.permisos = []
        } else {
          model.permisos = n
        }
      })
      onMounted(() => {
        usuariosStore.commit('limpiarUsuarioData')
        getLocalizaciones()
        usuariosStore.dispatch('getDocumentTypes')
        getActive(['ajustes.usuarios.editar.informacion'])
        findUsuario()
        usuariosStore.dispatch('obtenerSedes')
        usuariosStore.dispatch('obtenerAreas')
        usuariosStore.dispatch('obtenerCargos')
      })
      return {
        guardar,
        items,
        errors,
        model,
        validar,
        saving,
        esEmpleado
      }
    }
  }
  </script>

  <style scoped>
    ::v-deep(.p-selectbutton .p-button.p-highlight){
      background: transparent;
      border-color: transparent;
      color: #1C3FAA;
      border-bottom: 2px solid #1C3FAA;
      font-weight: 600;
      font: normal normal bold 17px/20px Roboto;
      width: 50%;
      place-content: center;
    }
    ::v-deep(.p-selectbutton .p-button.p-highlight:hover){
      background: transparent;
      border-color: transparent;
      color: #1C3FAA;
      border-bottom: 2px solid #1C3FAA;
      font-weight: 600;
      font: normal normal bold 17px/20px Roboto;
    }
    ::v-deep(.p-selectbutton .p-button){
      background: transparent;
      border-color: transparent;
      color: var(--unnamed-color-7f7e81);
      font: normal normal normal 14px/16px Roboto;
      width: 50%;
      place-content: center;
     }
    ::v-deep(.p-selectbutton .p-button:hover){
      background: transparent;
      border-color: transparent;
      color: var(--unnamed-color-7f7e81);
      font: normal normal normal 14px/16px Roboto;
    }
    .buttonBack {
      color: #7F7E81;
    }
    .buttonBack:hover {
      color: #1C3FAA;
    }
    .on {
      border-bottom: 3px solid #1C3FAA;
      width: 50%;
      font-weight: 600;
      font: normal normal bold 17px/20px Roboto;
      text-align: center;
      color: #1C3FAA;
    }
    .off {
      text-align: center;
      width: 50%;
    }
    :global(.swal2-container) {z-index: 1000000 !important}
  </style>
